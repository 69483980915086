import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Prompt`}</h1>
    <p>{`A `}<a parentName="p" {...{
        "href": "/conversation",
        "title": "conversation"
      }}>{`conversation`}</a>{` `}<a parentName="p" {...{
        "href": "/topic",
        "title": "topic"
      }}>{`topic`}</a>{`, or `}<a parentName="p" {...{
        "href": "/prompt",
        "title": "prompt"
      }}>{`prompt`}</a>{`, tells `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` what the conversation is about, and sets the frame, scope, and tone for the `}<a parentName="p" {...{
        "href": "/comments",
        "title": "comments"
      }}>{`comments`}</a>{` they should submit. `}</p>
    <p>{`The prompt is made up of two fields, the `}<strong parentName="p">{`Topic`}</strong>{` and the `}<strong parentName="p">{`Description`}</strong>{`. `}</p>
    <ul>
      <li parentName="ul">{`The Topic should briefly summarize the question(s) you'd like the participants to respond to with `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{`, and `}</li>
      <li parentName="ul">{`The Description should expand on the Topic, giving examples, linking to an article, asking specific sub-questions, or mentioning what's off-topic.`}</li>
    </ul>
    <p>{`Note: These are both optional fields; you might decide to describe the conversation topic in a different spot (especially if you're embedding the conversation) or on a different page than the Polis conversation. `}</p>
    <h2>{`Best practices`}</h2>
    <p><strong parentName="p">{`Open-ended conversations are good`}</strong>{` - If the topic is too small, the results won't be interesting. Examples:`}</p>
    <ul>
      <li parentName="ul">{`"What's your experience and how can we make things better?"`}</li>
      <li parentName="ul">{`“What should we start, stop, or continue doing?”
But topics can also be quite specific, while being large enough to produce interesting results. For example:`}</li>
      <li parentName="ul">{`"How should we protect and restore biodiveristy over the next 50 years?"`}</li>
    </ul>
    <p><strong parentName="p">{`Polis is best for controversial issues with a diversity of opinions
`}</strong>{` - If there aren't many possible points of view on a topic, the results probably won't be very interesting.
`}<strong parentName="p">{`Keep it short`}</strong>{` - Polis is designed to let participants jump right in and vote on statements. Long descriptions of the topic probably won't be read by your participants, or worse, they'll make them less likely to participate. If you need to include more information to educate people before they participate, use other channels like in the emails used to distribute the Polis link, or on a website that has the survey embedded.`}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/educating-participants",
        "title": "educating participants"
      }}>{`educating participants`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      